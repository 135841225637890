<template>
	<div style="min-height: calc(100vh + 130px);" v-loading="fullscreenLoading"
		element-loading-text="ئۇچۇر يۈكلىنىۋاتىدۇ" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)">
		<homeHeader v-if="showView" v-on:formSubmit='load' v-on:login="login" />
		<div class="site-header">
			<div class="logo"><img src="../../assets/Gulum.svg" /></div>
			<div class="slider">
				<img src="https://fpcover.cdnpk.net/image/20220224094945.jpg" referrerpolicy='no-referrer' />
				<!-- <video autoplay="true" loop="loop" src="https://ripro.rizhuti.com/wp-content/uploads/2022/01/1642923665-7b0d6f7dc161eaf.mp4"/> -->
				<a class="hero__link" href="https://www.freepik.com/premium-vector/flat-spring-background_22773511.htm">
					يۇقارقى تەگلىك رەسىمنى چۈشۈرۈش</a>
			</div>
			<div class="container">
				<div class="hero_content">
					<div class="text">ھەممىگە ماس كېلىدىغان لايىھە بايلىقى</div>
					<div class="content">ئېلىمېنت، ۋېكتورلۇق رەسىم ۋە PSD خام ماتېرياللارنى ئىزدەش</div>
					<div class="search">
						<div class="popover">
							<span>بارلىق</span>
						</div>
						<input type="text" placeholder="ئىزدەش سۆزىنى كىرگۈزۈپ ئىزدەڭ" />
					</div>
					<div class="example-keywords">
						كۆپ ئىزدەلگەنلىرى:
						<span>تاماق</span>
						<span>تاماق</span>
						<span>تاماق</span>
						<span>تاماق</span>
					</div>
					<div class="horizontal_button">
						<router-link to="popular" class="popularButton">تەۋسىيە</router-link>
						<router-link to="new" class="popularButton new">ئەڭ يېڭى</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="nav">
			<ul>
				<div class="item current">تەۋسىيە</div>
				<div class="item" v-for="(item,index) in category">{{item.category_name}}</div>
			</ul>
		</div>

		<!-- <router-link to="down" class="weli">down</router-link> -->

		<div class="g-wrap">

			<div class="demo">
				<div class="fleximage-item" v-for="(item,index) in list" :key="index" :data-w="item.width"
					:data-h="item.height" :style="'background-color:'+item.bgcolor+' ;'">
					<router-link :to="{name: 'show', params: { id: item.id }}" target="_blank">
						<img :src="item.image" referrerpolicy='no-referrer'>
					</router-link>
					<div class="info">
						<div class="name">{{item.title}}</div>

						<div class="userInfo">
							<router-link :to="'abduweli'" target="_blank">
								<div class="user">
									<div class="avatar">
										<img :src="item.image" />
									</div>
									<div class="username">abduweli</div>
								</div>
							</router-link>
							<div class="like">45</div>
						</div>
					</div>
					<div class="premium" v-if="item.is_vip"></div>
				</div>
			</div>

			<div class="btn-box">
				<button class="btn-append" @click="load()" type="button">تېخىمۇ كۆپ</button>
			</div>
		</div>

		<div class="loginModal" @click="login" v-if="showLoginModal" />
		<div class="loginMain" v-if="showLoginModal">
			<div class="right">
				<div class="textTitle">ياخشىمۇ سىز، خۇش كەلدىڭىز!</div>
				<div class="image">
					<img src="https://images.ui8.net/res/robot/image/upload/login-icon_2x_uigfmg/1504789308.png" />
				</div>
				<div class="registerText">تېخى تىزىملاتمىغان بولسىڭىز بۇ يەردىن تىزىملىتىڭ</div>
			</div>
			<div class="left">
				<div class="user">
					<div class="input">
						<input type="text" v-model.trim="userLogin.name" placeholder="ئەزالىق ئىسمىڭىزنى كىرگۈزۈڭ" />
					</div>
					<div class="input">
						<input type="password" v-model.number="userLogin.pass"
							placeholder="مەخپىي نومۇرىڭىزنى كىرگۈزۈڭ" />
					</div>
					<div class="input button">
						<button type="button" @click="userlogin()">كىرىش</button>
					</div>
					<div class="forgot-link">
						<span>مەخپىي نومۇرنى ئۇنتۇپ قالدىڭىزمۇ؟</span>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		FlexImage,
		Dt
	} from '@/assets/flexImage.js'
	import {
		index,
		category,
		login
	} from '@/api/admin/sucai'
	import md5 from 'js-md5';
	import homeHeader from '@/components/homeHeader.vue'
	export default {
		name: 'free',
		components: {
			homeHeader
		},

		data() {
			return {
				fullscreenLoading: false,
				list: [],
				category: [],
				showLoginModal: false,
				userLogin: {
					name: '',
					pass: ''
				},
				showView: true,
			}
		},


		methods: {
			load(event) {
				this.category = event
			},

			refreshView() {

				this.showView = false // 通过v-if移除router-view节点
				this.$nextTick(() => {
					this.showView = true // DOM更新后再通过v-if添加router-view节点
				})

			},

			login() {
				console.log('click login')
				this.showLoginModal = !this.showLoginModal
				this.refreshView()
			},

			userlogin() {
				let name = this.userLogin.name
				let pass = this.userLogin.pass
				login({
					name: name,
					pass: pass
				}).then(res => {
					if (res.status == 200) {
						console.log('login', res)
						this.$message({
							message: res.msg,
							type: 'success'
						});
						localStorage.setItem('xx-token', res.data)
						this.login()
					}
				}).catch(err => {
					console.log('fucker', err);
				});
			},

			homeApi() {
				this.fullscreenLoading = true
				index().then(res => {
					if (res.status == 200) {
						this.fullscreenLoading = false
						this.list = res.data.sucai
						// list(res.data.sucai)
					}
				}).catch(err => {
					console.log('fucker', err);
				});
			},

		},

		mounted() {

			// FlexImage(document.querySelector('.demo'), {
			// 	rowHeight: 300,
			// 	listenResize: true
			// });
			this.homeApi()
		},

		updated() {
			FlexImage(document.querySelector('.demo'), {
				rowHeight: 300,
				listenResize: true
			});
		}

	}
</script>
<style>
	.site-header {
		overflow: hidden;
		position: relative;
		height: 300px;
		padding: 70px 0;
	}

	.site-header::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(8, 25, 43, 0.45);
		z-index: 1;
		content: '';
	}

	.site-header .slider {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.site-header .slider .hero__link {
		position: absolute;
		bottom: 10px;
		left: 10px;
		z-index: 2;
		color: #fff;
		text-decoration: none;
		font-weight: bold;
		font-size: 12px;
	}

	.site-header .slider img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.site-header .slider video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.site-header .logo {
		overflow: hidden;
		position: absolute;
		top: 20px;
		right: 20px;
		width: 120px;
		z-index: 2;
	}

	.site-header .logo img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.site-header .container {
		max-width: 1440px;
		text-align: center;
		margin: 0 auto;
		position: relative;
		z-index: 2;
		color: #fff;
		padding-top: 20px;
	}

	.site-header .container .hero_content {
		max-width: 768px;
		position: relative;
		margin: auto;
		z-index: 2;
	}

	.site-header .container .text {
		font-size: 38px;
	}

	.site-header .container .content {
		font-size: 20px;
		padding: 20px 0;
		margin-bottom: 10px;
	}

	.site-header .container .search {
		background-color: #fff;
		height: 50px;
		border-radius: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;
	}

	.site-header .container .search .popover {
		overflow: hidden;
		position: relative;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.site-header .container .search .popover::before {
		position: absolute;
		top: 50%;
		left: 0;
		width: 1px;
		height: 60%;
		transform: translateY(-50%);
		background-color: #cfd9e0;
		content: '';
	}

	.site-header .container .search .popover span {
		display: block;
		min-width: 180px;
		max-width: 180px;
		height: 50px;
		border: none;
		color: #374957;
		background-color: transparent;
		position: relative;
		line-height: 50px;
		text-align: right;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 20px;
	}

	.site-header .container .search .popover span::after {
		position: absolute;
		top: 50%;
		left: 20px;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
		height: 0;
		width: 0;
		border-left: 4px solid rgba(0, 0, 0, 0);
		border-right: 4px solid rgba(0, 0, 0, 0);
		border-top: 4px solid #374957;
		content: "";
	}

	.site-header .container .search input {
		width: 570px;
		outline: none;
		height: 48px;
		border: 0;
		padding: 0 10px;
		font-size: 18px;
		color: #374957;
	}

	.site-header .container .search input::-webkit-input-placeholder {
		font-size: 16px;
	}

	.site-header .container .example-keywords {
		color: rgba(255, 255, 255, .5);
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		padding-top: 10px;
	}

	.site-header .container .example-keywords span {
		color: #fff;
		padding: 0 5px;
	}

	.site-header .container .horizontal_button {
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 20px;
	}

	.site-header .container .horizontal_button .popularButton {
		background-color: #1273EB;
		min-width: 110px;
		max-width: 110px;
		text-align: center;
		color: #fff;
		border-radius: 3px;
		display: block;
		height: 45px;
		line-height: 45px;
		margin: 5px;
		text-decoration: none;
	}

	.site-header .container .horizontal_button .popularButton:hover {
		background-color: #0161cd;
	}

	.site-header .container .horizontal_button .popularButton.new {
		background-color: rgba(255, 255, 255, 0);
		color: #fff;
		box-shadow: inset 0 0 0 2px #fff;
	}

	.site-header .container .horizontal_button .popularButton.new:hover {
		background-color: rgba(255, 255, 255, .1);
	}

	.g-wrap {
		width: 100%;
		margin: 0 auto;
	}

	.demo {
		overflow: hidden;
		min-height: calc(100vh - 130px);
		margin: -5px 5px;
	}

	.fleximage-item {
		float: right;
		margin: 5px;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		border-radius: 3px;
	}

	.fleximage-item .info {
		overflow: hidden;
		background: linear-gradient(rgba(8, 25, 43, 0), rgba(8, 25, 43, 0.85)) top;
		position: absolute;
		bottom: 0px;
		right: 0px;
		left: 0px;
		z-index: 1;
		display: none;
		color: #fff;
		padding: 10px;
		transition: all ease 0.3s;
	}

	.fleximage-item .info .name {
		overflow: hidden;
		height: 30px;
		line-height: 30px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.fleximage-item .info .userInfo {
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.fleximage-item .info .userInfo .user {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
	}

	.fleximage-item .info .userInfo .user .avatar {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #fff;
	}

	.fleximage-item .info .userInfo .user .avatar img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: block;
	}

	.fleximage-item .info .userInfo .user .username {
		margin-right: 5px;
		overflow: hidden;
	}

	.fleximage-item .info .userInfo a {
		color: #fff;
		text-decoration: none;
	}

	.fleximage-item:hover .info {
		display: block;
		transition: all ease 0.3s;
	}

	.fleximage-item .premium {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 1;
		padding: 7px;
		border-radius: 3px;
		background-color: rgba(8, 25, 43, 0.65);
		cursor: pointer;
	}

	.fleximage-item .premium::before {
		width: 14px;
		height: 14px;
		background-size: 14px 14px;
		display: block;
		background: url('../../assets/pro.svg') center center no-repeat;
		background-size: 16px 16px;
		content: '';
	}


	.fleximage-item img {
		border-radius: 3px;
	}

	.btn-box {
		margin: 20px 0;
		height: 40px;
		text-align: center;
	}

	.btn-box button {
		display: inline-block;
		background: #1273eb;
		color: #fff;
		padding: 0 20px;
		line-height: 36px;
		cursor: pointer;
		border: none;
		border-radius: 3px;
	}

	.nav {
		border-bottom: 1px solid #cfd9e0;
		overflow: auto;
		display: grid;
		margin-bottom: 20px;
	}

	.nav ul {
		display: flex;
		flex-wrap: nowrap;
		height: 50px;
		max-width: 700px;
		margin: 0 auto;
	}

	.nav ul .item {
		flex: 1 0 auto;
		height: 49px;
		padding: 0 30px;
		cursor: pointer;
		color: #5f7d95;
		font-weight: 500;
		line-height: 49px;
	}

	.nav ul .item.current {
		color: #374957;
		border-bottom: 1px solid #cfd9e0;
		border-color: #374957;
	}

	.loginModal {
		overflow: hidden;
		background-color: rgba(0, 0, 0, .5);
		position: fixed;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 0px;
		z-index: 5;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.loginMain {
		width: 1086px;
		height: 534px;
		background-color: #353535;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 6;
		display: flex;
		justify-content: space-between;
		border-radius: 4px;
	}

	.loginMain .right {
		overflow: hidden;
		width: 443px;
		padding: 58px 50px 57px;
		background-color: #fff;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
	}

	.loginMain .right .textTitle {
		overflow: hidden;
		font-size: 20px;
		margin-bottom: 88px;
	}

	.loginMain .right .image {
		width: 200px;
		height: 200px;
		margin: 0 auto;
	}

	.loginMain .right .image img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
		display: block;
	}

	.loginMain .right .registerText {
		margin-top: 93px;
		color: #2f8be6;
		font-size: 12px;
	}

	.loginMain .left {
		overflow: hidden;
		width: 543px;
		height: 100%;
	}

	.loginMain .left .user {
		overflow: hidden;
		width: 448px;
		margin: 130px auto -40px auto;
	}

	.loginMain .left .user .input {
		width: 100%;
		height: 70px;
		margin: 0 0 40px 0;
		background: #2a2a2a;
		box-sizing: border-box;
		border: 2px solid rgba(42, 42, 42, 0);
		border-radius: 2px;
		position: relative;
		direction: ltr;
	}

	.loginMain .left .user .input input {
		width: 100%;
		height: 100%;
		outline: none;
		border: 0;
		padding: 0px 20px 0 20px;
		background: none;
		color: #fff;
		font-size: 14px;
		box-sizing: border-box;
	}

	.loginMain .left .user .button {
		height: 54px;
		background: none;
	}

	.loginMain .left .user .button button {
		width: 100%;
		border: 0;
		padding: 0 20px;
		height: 45px;
		display: -webkit-inline-box;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-image: linear-gradient(-180deg, #3db96d 0%, #1b904e 100%);
		border-radius: 4px;
		color: #fff;
		letter-spacing: .4px;
		outline: none;
		text-transform: uppercase;
		font-size: 11px;
		cursor: pointer;
	}

	.loginMain .left .user .button button:hover {
		background: #2aa461;
	}

	.loginMain .left .user .forgot-link {
		color: #a2a2a2;
		font-size: 12px;
		line-height: 1;
		padding-top: 13px;
		text-align: center;
	}

	.loginMain .left .user .forgot-link span {
		cursor: pointer;
	}
</style>
